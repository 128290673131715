import axios from 'axios';
import { Notify } from 'vant'

axios.defaults.baseURL = "https://duojietai.com/jiaxiao"
// 测试接口: https://duojietai.com/test
// 线上接口： https://duojietai.com/jiaxiao
axios.defaults.headers["Content-Type"] = "application/json;charset=UTF-8"

// 请求拦截
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('carToken')) {
      const tokenId = localStorage.getItem('carToken') || ''
      config.headers['Authorization'] = tokenId
    }
    return config
  },
  error => {
    return Promise.reject(error)
  } 
)

// 对错误做出响应
axios.interceptors.response.use(
  response => {
    if (response.data.httpStatus === 401) {
      localStorage.removeItem("carToken")
      Notify({ type: 'danger', message: `${response.data.message}` })
      return false
    } else if (response.data.httpStatus === 402) {
      Notify({ type: 'danger', message: `${ response.data.message }`})
      return false
    } else if (response.data.httpStatus === 500) {
      Notify({ type: 'danger', message: `${ response.data.message }`})
      return false
    } else {
      return Promise.resolve(response.data)
    }
  },
  error => {
    return Promise.reject(error)
  }
)