<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  created() {
    document.title = '多杰泰驾考'
  }
}
</script>
<style lang="scss">
</style>
