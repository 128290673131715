import Vue from 'vue'
import VueI18n from 'vue-i18n'
import anduo from './zh.js'
import gansu from './en.js'
import weiz from './weiz.js'

Vue.use(VueI18n)
let i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'anduo',
  messages: {
    'anduo': {
      ...anduo
    },
    'gansu': {
      ...gansu
    },
    'weiz': {
      ...weiz
    }
  }
})
export default i18n;